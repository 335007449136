.search__recommendeds,
.search-results {
  transition: opacity .3s ease-in-out, max-height 0.3s ease-in-out;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.search__recommendeds.active,
.search-results.active {
  margin-left: 342px;
  opacity: 1;
  max-height: 300px;
  z-index: 2;
}


.user_header {
  width: 100%;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background: var(--extended-gray-50, #F4F4F5);
  margin-bottom: 2px;



  .left__side {
    display: flex;
    align-items: center;
    gap: 12px;

    .arrow__down {
      height: 22px;
      width: 22px;
    }
  }

  .right__side {
    gap: 18px;
    margin-left: auto;
    display: flex;
    align-items: center;

    .search_side_bar {
      display: flex;
    }
  }

  @media (min-width: 768px) {
    height: 96px;
    padding: 16px 62px;

    width: calc(100% - 292px);
    margin-left: 292px;

    .left__side {
      display: none;
    }

    .right__side {
      gap: 24px;
      justify-content: flex-end;
    }

    .search_side_bar {
      display: flex;
      transition: all 0.3s ease;
      width: 40px;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .search_side_bar.actived {
      // margin-left: 292px;
      transition: all 0.3s ease;
      width: 849px;
      border-radius: 16px;
      background: var(--default-white, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      height: 56px;
      padding: 0px 8px;
      gap: 8px;

      img {
        width: 32px;
        height: 32px;
      }

      input {
        height: 30px;
        outline: none;
        border: none;
        color: var(--default-gray-800, #1F2937);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      .search__closeicon {
        margin-left: auto;
        width: 32px;
        height: 16px;
        cursor: pointer;
      }
    }

    img {
      cursor: pointer;
    }
  }
}

.search_results_title {
  color: var(--default-gray-800, #1F2937);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-left: 60px;
  margin-top: 24px;
}

.search__recommendeds {
  position: absolute;
  display: flex;
  width: 849px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--default-white, #FFF);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
  margin-left: 62px;
  margin-top: -10px;
  padding: 28px;
  gap: 10px;

  span {
    color: var(--default-gray-800, #1F2937);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    width: 100%;
    height: 35px;
    border-radius: 8px;
    padding: 8px;
  }

  .selected {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.filter_container {
  margin-left: 342px !important;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 40px;

  &_button {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--extended-cool-gray-100, #F3F4F6);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    border: none;
    color: var(--default-gray-800, #1F2937);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    cursor: pointer;
    transition: all 0.3s ease;


  }

  &_button.active {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--extended-cool-gray-800, #1F2937);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    color: var(--default-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
}


@media (max-width:840px) {

  .search__recommendeds.active,
  .search-results.active {
    margin-left: 2px;
    opacity: 1;
    max-height:2px;
    z-index: 2;
  }

}