@import "../../assets/styles/colors.scss";

.landing__container {


  &__top {
    display: flex;
    flex-direction: column;
    color: black;
    position: relative;
    z-index: 1;
    margin: 32px 20px 0px;

    &__mobile__button {
      display: block;
    }

    &_info {
      &__header {
        color: var(--default-gray-800, #1f2937);
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;

        img {
          margin-bottom: -10px;
          margin-right: 8px;
          width: 40px;
          height: 40px;
        }
      }

      h3 {
        color: var(--default-gray-600, #4b5563);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 16px 0px;
        text-align: left;
      }

      &__button {
        display: none;
      }
    }
  }

  .landing__banner {
    width: 100%;
    min-width: 356px;
    max-width: 450px;
  }

  &__container {
    padding: 0px 20px;
    margin-bottom: 56px;

    &__heading {
      color: var(--default-gray-800, #1f2937);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }

    &__platforms {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 22px;
    }
  }

  &__details__container {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 20px;
    align-items: center;

    &__image {
      width: 100%;
    }

    &__info {
      &__title {
        color: var(--default-gray-800, #1f2937);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;
      }

      &__text {
        color: var(--default-gray-600, #4b5563);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__footer {
    margin: 56px 20px;

    &__title {
      color: var(--default-gray-800, #1f2937);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
    }

    &__services {
      display: flex;
      flex-direction: row;
      gap: 32px;
      max-width: 400px;
      padding-bottom: 20px;

      overflow-x: auto;

      &__img {
        object-fit: contain;
        max-height: 60px;
      }
    }

    &__services::-webkit-scrollbar {
      display: none;
    }
  }

  @media (min-width: 768px) {
    // max-width: 1440px;
    margin: 0 auto;

    &__top {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin: 70px 40px 0px;

      &__mobile__button {
        display: none;
      }

      &_info {
        max-width: 45%;
        min-width: 45%;
        &__header {
          color: var(--default-gray-800, #1f2937);
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          img {
            margin-bottom: -10px;
            margin-right: 8px;
            width: 53px;
            height: 53px;
          }
        }

        h3 {
          color: var(--default-gray-500, #6b7280);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 40px !important;
        }

        &__button {
          display: block;
          width: 336px;
          border-radius: 24px !important;
        }
      }
    }

    .landing__banner {
      height: auto;
      width: 100%;
      max-width: 700px;
      float: right;
      animation: slideUp 1s forwards;
      opacity: 0;
    }

    &__container {
      padding: 0px 66px;
      margin-bottom: 38px;

      &__heading {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 32px;
      }

      &__platforms {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
      }
    }

    .animate-banner {
      animation: slideUp 1s forwards;
    }

    &__details__container {
      padding: 0px 66px;
      flex-direction: row;
      gap: 150px;

      &__image {
        width: 615px;
        height: 517px;
        flex-shrink: 0;
      }

      &__info {
        &__title {
          color: var(--default-gray-800, #1f2937);
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%;
          margin-bottom: 16px;
        }

        &__text {
          color: var(--default-gray-500, #6b7280);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 40px;
        }
      }
    }

    &__footer {
      margin: 56px 66px;
      max-width: none;

      &__title {
        color: var(--default-gray-800, #1f2937);
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 31px;
      }

      &__services {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 56px;
        max-width: none;

        &__img {
          object-fit: contain;
        }
      }
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}