.avatar {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 40px;
  cursor: pointer;

  .avatar-img {
    border-radius: 50px;
    border: 2px solid var(--merge-gradient-1, #af72ff);
    background: #d3d3d3 -23.684px -20.693px / 156.461% 234.691% no-repeat;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    height: 40px;

    img {
      width: 40px;
    }
  }

}