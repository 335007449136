.logo {
  min-width: 40px;
  color: var(--merge-primary-100, #6b37fe);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    height: 24px;
    width: 24px;
  }

  @media screen and (min-width: 768px) {
    font-family: "PlusJakartaSans SemiBold";
    display: block;
    color: #6b37fe;
    text-align: center;
    font-size: 27px;
    font-weight: 700;
    line-height: normal;
    margin-right: 18px;
    cursor: pointer;
    text-decoration: none;
    margin-top: -6px;
    width: auto;


    img {
      display: inline-block;
      margin-right: 8px;
      margin-bottom: -10px;
      height: auto;
      width: auto;
    }
  }
}