.divider {
  width: 100%;
  height: 1px;
  background-color: var(--default-gray-200, #e5e7eb);
  margin: 24px 0px;

  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.profile_container {
  margin-left: 292px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
}
.profile__content {
  width: 100%;
  display: flex;
  padding: 16px 20px;
  gap: 28px;
  flex-direction: column;
 

  .__myProfile {
    display: flex;
    flex: row;
    align-items: center;
    gap: 12px;

    .__title {
      color: var(--default-gray-800, #1f2937);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }

  .profile__avatar {
    width: 134px;
    height: 134px;
    border-radius: 50%;
    align-self: center;
    

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      min-width: 85px;
      border: 2px solid var(--merge-gradient-1, #af72ff);
    }
  }

  .edit_profile_button {
    background-color: transparent;
    color: #8d66fe;
    border-radius: 24px;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    border: 1px solid var(--merge-gradient-1, #af72ff);
  }

  @media (min-width: 768px) {
    flex-direction: row;
    width: 100%;
    padding: 44px 40px;

    .__myProfile {
      display: none;
    }
    .profile__avatar {
      width: 110px;
      height: 110px;
      min-width: 75px;
    }

    .edit_profile_button {
      background-color: transparent;
      color: #8d66fe;
      width: 228px;
      border-radius: 24px;
      border: 1px solid var(--merge-primary-100, #6b37fe);
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.password__container {
  padding: 0px 20px;

  .password__title {
    color: var(--default-gray-800, #1f2937);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 24px;
  }

  .password__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 12px;
    flex-wrap: wrap;
    .change_password_button {
      display: flex;
      width: 100%;
      height: 48px;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-left: auto;
      order: 2;
      background-color: transparent;
      color: #6b37fe;
      border: 1px solid var(--merge-gradient-1, #6b37fe);
      border-radius: 24px;

      &:hover {
        background-color: var(--merge-gradient-1, #6b37fe);
        color: #fff;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 0px 40px;

    .password__content {
      .change_password_button {
        width: 228px;
      }
    }
  }
}


