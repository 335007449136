.title {
  color: var(--default-gray-500, #6b7280);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.info {
  color: var(--default-gray-800, #1f2937);
  margin-top: 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 768px) {
  .title {
    color: var(--default-gray-500, #6b7280);
    /* Merge/Main */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  .info {
    color: var(--default-gray-800, #1f2937);

    /* Merge/H4 */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
  }
}
