@import url('assets/styles/colors.scss');

.episode-card {
  display: flex;
  margin: 16px;
  border-radius: 2px;
  flex-direction: column;
  gap: 12px;



  .episode-card-container {
    position: relative;

    .episode-card-platform {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
    }
  }



  &__info {
    display: flex;
    align-items: center;
    gap: 16px;

    .episode-card-img {
      display: flex;
      width: 56px;
      height: 56px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 2px;
    }

    .episode-name {
      color: var(--default-gray-800, #1F2937);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .episode-date-and-length {
      color: var(--default-gray-500, #6B7280);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .episode-card-options {
      margin-left: auto;
    }
  }

  &__description {
    overflow: hidden;
    color: var(--default-gray-500, #6B7280);
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

}