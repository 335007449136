.music__page__container {
  padding: 37px 0px;
  margin-left: 292px;
  img {
    cursor: pointer;
  }

  &__top {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 40px;
    margin-top: 20px;

    &__title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  &__madeforyou {
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    gap: 28px;
    overflow-x: auto;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
      display: none;
    }

    .music__item__container {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      min-width: 248px;
      max-width: 260px;

      .music__item__container__img {
        min-width: 240px;
        object-fit: cover;
        max-width: 240px;
      }

      .music__item__container__link {
        gap: 4px;
        text-decoration: none;
        display: flex;
        flex-direction: column;
      }

      &__title {
        margin-top: 4px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        
      }

      &__subtitle {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  @media (max-width: 768px) {
    margin-left: 0px;
    padding: 28px 20px;
    margin-bottom: 20px;
    &__top {
      padding: 0px;
      margin: 16px 0px;
      &__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    &__madeforyou {
      padding: 0px;
      gap: 16px;
      
      .music__item__container__img {
        width: 100%;
        min-width: 40px !important;
      }

      .music__item__container {
        min-width: 101px;
        border-radius: 6px;
        height: auto;
        overflow: hidden;

       

        &__title {
          font-size: 12px;
          font-style: normal;
          font-weight: bold;
          line-height: normal;
        }

        &__img {
          // max-width: 110px;
          width: 100%;
          height: auto;

        }
      }
    }
  }
}