.playlist_container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;


    .playlist__title {
        color: var(--default-gray-800, #1F2937);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 12px;
    }

    .playlist__subtitle {
        color: var(--default-gray-700, #374151);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 8px;
    }

    .provider-img {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

@media (max-width: 840px) {
    .playlist_container {


        width: 100px;

        .provider-img {
            width: 20px;
            height: 20px;
            top: 5px;
            right: 5px;
        }

        .playlist__img {
            height: 100px;
        }

        .playlist__title {
            font-size: 14px;
        }

        .playlist__subtitle {
            font-size: 10px;
        }
    }
}