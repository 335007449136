.movie-section-title {
    display: flex;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 20px;
    gap: 24px;


    .arrows-container {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 36px;


        .button-arrow {
            cursor: pointer;
            width: 40px;
            height: 40px;
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10));
            border-radius: 12px;
            border: none;
            outline: none;

            .arrow-previous {
                transform: rotate(90deg);
            }
        }

        .arrow-next {
            transform: rotate(270deg);
        }
    }


}

.movie-list-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    // flex-wrap: wrap;
    gap: 28px;
    max-width: 100vw;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width:840px) {
    .movie-section-title {
        h2 {
            color: #1E293B;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .arrows-container {
            display: none;
        }
    }
}

.shelf-list-mobile {

    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    max-width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        display: none;
    }

}