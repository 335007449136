.buttons-settings-container {
    display: flex;
    align-items: center;
    gap: 12px;

    .play_button {
        border-radius: 40px;
        background: var(--merge-primary-100, #6B37FE);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
        border: none;
        outline: none;
        margin-right: 12px;
        cursor: pointer;
        z-index: 1;
    }

    .icon_button {
        outline: none;
        border: none;
        background-color: transparent;
        margin-right: 12px;
        cursor: pointer;
        z-index: 1;
    }
}


.buttons__container {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: row;
    margin-bottom: 12px;

    button {
        border-radius: 40px;
        background: var(--extended-cool-gray-100, #F3F4F6);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
        border:none;
        height: 48px;
        width: 48px;
        z-index: 1;
    }


}