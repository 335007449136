.__platforms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 22px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
}
