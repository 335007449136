.startPage_container {
  margin: 40px 20px;
  display: flex;
  flex-direction: column;

  h1 {
    color: var(--extended-gray-700, #3f3f46);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; 
  }

  & h1:last-of-type {
    margin-bottom: 24px;
  }

  @media screen and (min-width: 768px) {
    justify-content: center;
    align-items: center;
    margin-top: 160px;
    margin-bottom: 40px;

    h1 {
      color: var(--default-gray-800, #1f2937);
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }
}
