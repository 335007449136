.back-button {
    border-radius: 36px;
    display: flex;
    align-items: center;
    border-radius: 36px;
    padding: 16px 32px;
    outline: none;
    border: 1px solid white;
    background-color: transparent;
    gap: 10px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;

    /* 22.4px */
    .arrow-back-button {
        transform: rotate(90deg);
    }
}