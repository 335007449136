.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading img {
    animation: spin 1s linear infinite;

    @media (max-width:768px) {
        width: 100px;
        height: 100px;
    }
}
