@import "../../assets/styles/colors.scss";

.stream_card {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
  gap: 11px;
  border-radius: 8px;
  border: 1px solid var(--default-gray-200, #e5e7eb);
  background: var(--default-gray-50, #f9fafb);
  /* /shadow/base */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &__button, .add_button {
    margin-left: auto;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border: none;
    background-color: transparent;
    border: 1px solid #ddd4fa;
    border-radius: 50%;
    cursor: pointer;
  }

  &__name__container {
    display: flex;
    flex-direction: column;
    &__title {
      color: var(--default-gray-800, #1f2937);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    &__streamTitle {
      color: var(--default-gray-400, #9ca3af);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }

  @media (min-width: 768px) {
    width: 251px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 20px;
    gap: 11px;

    &__title {
      color: $--default-gray-500;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    &__button {
      margin-left: auto;
      display: flex;
      width: 40px;
      height: 40px;
      padding: 0;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;
      border: none;
      background-color: transparent;
      border: 1px solid #ddd4fa;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: #f9fafb;
      }
    }
  }
}
