.movie-page-container {
    margin: 40px 44px;
}

.tvshow-details-container {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-top: 10%;
}

.tvshow-details-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.episode-title {
    color: var(--default-gray-800, #1F2937);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
}

.season-container {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 28px;
    &:webkit-scrollbar {
        display: none;
    }
}