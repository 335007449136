.modal-container {
    min-width: 950px;
    height: 889px;
    padding: 40px 32px;
    position: relative;


    .modal-title {
        color: var(--default-gray-800, #1F2937);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 12px;
    }

    .modal-info {
        color: var(--default-gray-600, #4B5563);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 24px;
    }

    .modal-close {
        position: absolute;
        top: 32px;
        right: 22px;
    }

    .modal-search {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 16px;
        padding: 12px 16px;
        border-radius: 16px;
        border: 1px solid var(--default-gray-200, #E5E7EB);
        background: var(--default-white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

        .modal-search-input {
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    .modal-recommends-title {
        margin-top: 24px;
        margin-bottom: 16px;
    }

    .modal-recommended-songs {
        height: 250px;
        overflow-y: auto;
        margin-bottom: 20px;
    }

    .modal-create-radio-button {
        width: 228px;
        border-radius: 24px;
        background: var(--merge-primary-100, #6B37FE);
        display: flex;
        width: 228px;
        height: 48px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        text-align: right;
        margin-left: auto;
        margin-top: auto;
        color: #FFF;
        cursor: pointer;
    }


}

@media (max-width: 768px) {
    .modal-container {
        // width: 100vw !important;
        min-width: 350px;
        padding:20px;

        .mobile-create-radio {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 20px;

            .text-create-radio {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }

            .text-cancel {
                margin-left: auto;
                color: var(--default-red-500, #EF4444);
                text-align: right;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                cursor: pointer;
            }
        }

        .modal-info {
            color: var(--default-gray-500, #6B7280);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-top: 12px;
        }

        .button-container {
            margin-top: auto;
            padding-top: 10px;
            border-top: 1px solid gray;
            background-color: red;
        }
        .modal-create-radio-button {
            width: 100%;
        }

        .modal-recommended-songs {
            height: 300px;
            overflow-y: auto;
            margin-bottom: 20px;
        }
    }

}