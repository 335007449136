.button-add-playlist {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    background: var(--extended-cool-gray-100, #F3F4F6);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
}

.mobile-movie-description {
    margin-top: -140px;
    padding: 20px;

    .mobile-movie-title {
        color: var(--default-white, #FFF);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
    }

    .movie-info-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 8px;

        span {
            font-size: 12px;
        }
    }


    .mobile-movie-buttons-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        margin-top: 30px;

        .mobile-button-watch {
            display: flex;
            width: 100%;
            height: 48px;
            padding: 12px 32px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 36px;
            background: var(--merge-gradient-button, linear-gradient(180deg, #6B37FE 21.43%, #CA37FE 164.09%));
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
            border: none;
        }

        .mobile-button-watch-trailer {
            display: flex;
            width: 100%;
            height: 48px;
            padding: 8px 32px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 36px;
            background: var(--extended-cool-gray-100, #F3F4F6);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
        }

        .button-add-playlist {
            display: flex;
            width: 48px;
            height: 48px;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 36px;
            background: var(--extended-cool-gray-100, #F3F4F6);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
        }
    }
}

.mobile-similar-movies {
    padding: 20px;

    .mobile-similar-movies-title {
        color: #1E293B;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.img-background {
    width: 100%;
    height: 100%;
    background-size: cover !important;
    border-radius: 8px;
}