.base {
  display: flex;
  flex-direction: row;
  background: var(--extended-gray-50, #FAFAFA);
  padding-bottom: 100px;

  .base_sidebar {
  }

  &__content {
    width: 100vw;

  }

  @media (min-width: 768px) { 
    display: flex;
    flex: 1;

    &__content {
      // min-width: 1240px;
      // margin-left: 292px;
    }

  }


}