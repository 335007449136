@import "../../assets/styles/colors.scss";

.header-nav {
  .desktop {
    display: none;
  }
  ul {
    display: flex;
    height: 68px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    .header__mobile__button {
      width: 120px;
      height: 40px;
      float: right;
    }

    li {
      color: var(--default-gray-700, #374151);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      display: none;
      cursor: pointer;

      &:first-child {
        display: block;
      }

      &:last-child {
        display: block;
        outline: none;

        &:focus {
          pointer-events: none;
        }
      }


    }
  }

  @media (min-width: 768px) {
    border-bottom: 1px solid var(--default-gray-200, #e5e7eb);
    background-color: transparent;
    
    ul {
      height: 96px;
      display: flex;
      align-items: center;
      list-style: none;
      gap: 32px !important;
      padding: 12px 40px;

      .header__mobile__button {
      }

      li {
        display: block;
        background-color: transparent;
        position: relative;
      }
    }
  }
}
