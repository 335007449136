.__list {
  margin-top: 40px;
  padding: 0px 20px;

  h2 {
    color: var(--default-gray-800, #1f2937);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 24px;
  }

  &__items {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    gap: 24px;
    margin-top: 56px;

    h2 {
      color: var(--default-gray-800, #1f2937);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    &__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      align-items: center;
      justify-self: center;
      justify-content: center;
    }
  }
}
