@import url("../../assets/styles/colors.scss");

.sidebar__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  justify-content: center;
  z-index: 100 !important;

  &__logoDropdown {
    display: none;
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: space-around;
    padding: 10px;
    width: 100%;

    .sidebar__item {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      height: 50px;

      span {
        color: var(#1F2937);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  @media (min-width: 768px) {
    position: fixed;
    display: block;
    padding: 24px;
    border-right: 1px solid var(--extended-gray-200, #e4e4e7);
    background: var(--extended-gray-50, #fafafa);
    height: 100vh;
    top: 0px;
    min-width: 292px;
    width: 292px;
    z-index: 2;


    &__logoDropdown {
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: unset;

      .sidebar__item {
        display: flex;
        flex-direction: row;
        justify-content: unset;
        align-items: center;

        span {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .sidebar__item:first-of-type {
        margin-top: 64px;
      }

      .sidebar__item.selected {
        color: var(--merge-primary-75, #8d66fe);
        font-weight: 500;
      }
    }
  }
}

.sidebar__item {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 12px;


  @media (max-width: 768px) {
    span {
      font-size: 13px !important;
    }
  }

  span {
    text-decoration: none;

  }

  &:hover {
    opacity: 0.8;
  }
}

.sidebar__item__subitems {
  display: flex;
  flex-direction: column;
  gap: 16px;

  a {
    color: var(--default-gray-800, #1F2937);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  @media(max-width:768px) {
    display: none;
  }
}