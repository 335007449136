@import url('../../assets/styles/colors.scss');

.radioPage__container {
    margin: 40px 29px;
    margin-left: 342px;

    &__podcast__details {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20px;
        height: 250px;


        &__image {
            width: 250px;
            height: 250px;
            min-width: 250px;
            background-image: url("../../assets/images/radio/add_new_photo.png");
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
        }

        &__info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 100%;

            span {
                overflow: hidden;
                color: var(--default-gray-700, #374151);
                text-overflow: ellipsis;
                whitespace: nowrap;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            .artist_image_name {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 10px;

                span {
                    color: var(--default-gray-800, #1F2937);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }



            .buttons__container {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 16px;
                position: relative;
                justify-content: flex-start;
                margin-top: auto;


                hr {
                    background: var(--default-gray-300, #E5E7EB);
                    width: 100%;
                    display: none;
                }

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                }

                .circle__button {
                    border-radius: 40px;
                    height: 48px;
                    width: 48px;
                    border: none;
                    cursor: pointer;
                    background-color: transparent;
                    z-index: 2;
                }

                .circle__button:first-of-type {

                    background: var(--merge-primary-100, #6B37FE);
                }
            }

        }


    }



    @media (max-width:768px) {
        margin: 0px;

        &__podcast__details {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 8px;


            &__image {
                width: 133px;
                height: 133px;
                background-size: contain;
                min-width: auto;
            }

            &__info {
                align-items: center;

                .artist_image_name {
                    display: none;
                }

                h2 {
                    font-size: 16px;
                }

                .podcast_name {
                    text-align: center;
                }

                .podcast_quantities {
                    // display: none;
                }

                .podcast_description {
                    overflow: hidden;
                    color: var(--default-gray-600, #4B5563);
                    text-align: center;
                    text-overflow: ellipsis;
                    whitespace: nowrap;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                    padding: 0px 20px;
                }

                .buttons__container {
                    justify-content: center;

                    hr {
                        background: var(--default-gray-300, #E5E7EB);
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}

.radioPage__soundorvideo {
    margin-left: 342px;
}

@media (max-width:840px) {
    .radioPage__soundorvideo {
        margin-left: 0px;
    }
}