
.provider-item {
    display: flex;
    gap: 10px;
    padding: 12px;
    border-radius: 16px;
    align-items: center;

    .checkbox-provider {
        height: 24px;
        width: 24px;
        margin-left: auto;
    }
}

.absolute-div {
    position: absolute;
    top:110%;
    z-index: 10;
    overflow: hidden;
    transition: all 0.3s ease;
    max-height: 0;
    width: 230px;
    background-color: #FFF;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

}

.absolute-div.open {
    max-height: 400px;
    transition: all 0.3s ease;
    padding:8px;
    z-index: 10;
}
