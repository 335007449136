.section__container {
    padding: 20px 40px;

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__title {
            color: var(--default-gray-800, #1F2937);
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
        }

        .search__bar {
            display: flex;
            align-items: center;
            padding: 16px 12px;
            gap: 12px;
            width: 524px;
            height: 56px;
            flex-shrink: 0;
            border-radius: 16px;
            border: 1px solid var(--default-gray-200, #E5E7EB);
            background: var(--default-white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        }

    }

    .items__container {
        display: flex;
        flex-direction: row;
        gap: 28px;
        margin-top: 40px;
        width: 100%;
        max-width: 1200px;
        flex-wrap: wrap;

        .item {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            // flex-basis: 200;
            gap: 8px;
        }
    }
}


@media (max-width: 840px) {
    .section__container {
        padding: 20px;

        .items__container {
            display: flex;
            flex-direction: row;
            gap: 28px;
            margin-top: 0px;
            width: 100%;
            flex-wrap: wrap;

            .item {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                // flex-basis: 200;
                gap: 8px;
                width: 100px;

                .music__item__container__img {
                    width: 100px;
                    height: 100px;
                }

                .item__item__container__title {
                    color: #334155;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .item__item__container__subtitle {
                    color: var(--default-gray-400, #9CA3AF);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}