* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "PlusJakartaSans Regular", sans-serif;
  // background-color: #fafafa;
  scroll-behavior: smooth;
}

*,
::after,
::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
}

:root {
  $--progress-bar-width: 0px;
  $--progress-bar-bg: red;
  $bg-dark-mode: rgba(55, 65, 81, 0.64);
}

button {
  cursor: pointer;
  border: none;
}

html body a {
  text-decoration: none !important; /* or any other value you want */
}