.profile__page__userinfo {
  display: flex;
  flex-direction: column;

  gap: 16px;
  @media (min-width: 768px) {
    gap: 24px;
    flex-direction: row;
  }
}
