.button {
  background-color: #6b37fe;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  color: #fafafa;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin: 20px 0px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #7839f0;
    
  }
  
}
