// Primary colors
$--merge-primary-1: #4e20d2;
$--merge-primary-100: #6b37fe;
$--merge-primary-3: #8d66fe;
$--merge-primary-4: #b79fff;
$--merge-primary-5: #ddd4fa;

// Other colors
$--default-color-blue: #6984f6;
$--default-color-green: #58bb44;
$--default-color-orange: #dd893b;
$--default-color-purple: #9c78d7;
$--default-color-cyan: #67bacc;

// Gray palette
$--default-gray-50: #f9fafb;
$--default-gray-300: #f3f4f6;
$--default-gray-400: #d1d5db;
$--default-gray-500: #9ca3af;
$--default-gray-600: #6b7280;
$--default-gray-700: #4b5563;
$--default-gray-800: #1f2937;
$--default-gray-900: #111827;


//dark mode
$bg-dark-mode: rgba(55, 65, 81, 0.64);