.movies-headerbar-mobile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 8px 0px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #FFF;
    z-index: 999;

    .movies-headerbar-mobile-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        height: 45px;

        span {
            color: var(--merge-primary-100, #6B37FE);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
        }
    }
}