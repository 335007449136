.movies_header_menus {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-left: 71px;

    h3 {
        color: var(--default-gray-800, #1F2937);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        cursor: pointer;
    }
}

@media (max-width:840px) {
    .movies_header_menus {
        display: none;
    }
}