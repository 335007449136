a {
  text-decoration: none;
}

.bg-image-component {
  cursor: pointer;
  display: flex;
  height: 134px;
  color: #fff;
  position: relative;

  background-position: center;
  width: 160px;
  border-radius: 8px;
  background: linear-gradient(0deg,
      rgba(103, 186, 204, 0.72) 0%,
      rgba(103, 186, 204, 0.72) 100%),
    lightgray 0px -67.719px / 100% 183.073% no-repeat;

  background-size: cover;

  .comming-soon-text {
    border-radius: 0px 12px 12px 0px;
    background: rgba(216, 216, 216, 0.52);
    backdrop-filter: blur(4.5px);
    color: var(--default-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    position: absolute;
    bottom: 16px;
    padding: 8px 12px;
  }

  h2 {

    font-size: 16px;
    background-color: transparent;
    padding-left: 14px;
    padding-top: 16px;
  }

  @media (min-width: 768px) {
    width: 244px;
    height: 207px;
    flex-shrink: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

    h2 {
      font-size: 24px;
      font-weight: 600;
    }
  }
}