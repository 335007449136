.profile-dropdown {
  display: flex;
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 258px;
  // right 20px;
  // padding: 12px;
  flex-direction: column;
  z-index: 999;
  visibility: hidden;
  bottom: 0;

  .switch-dark-mode-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    width: 100%;

    .switch-svg {
      margin-right: 20px;
    }

    .react-switch-bg {
      border-radius: 12px;
      background: var(--merge-gradient-button, linear-gradient(180deg, #6B37FE 21.43%, #CA37FE 164.09%)) !important;

      svg {
        display: none;
      }
    }
  }

  .profile__content {
    border-bottom: 1px solid #e5e7eb;
    padding: 12px;
    margin-bottom: 12px;
  }

  .profile__dropdown__list {
    .sidebar__item {
      display: flex;
      align-items: center;
      color: var(--default-gray-700, #374151);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      gap: 12px;
      padding: 12px;

      span {
        text-decoration: none;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    width: 100%;
    justify-content: center;
    color: var(--merge-primary-75, #8d66fe);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 12px;

    img {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .profile__content {
      display: none;
    }

    transition: all 0.4s ease;
    height: fit-content;

    align-items: flex-start;
    border-radius: 16px;
    position: fixed;
    top: 10px;

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(10px);
    right: 0;

    &__link {
      display: flex;
      align-items: center;
      color: var(--default-gray-700, #374151);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      gap: 12px;
      padding: 12px;

      span {
        text-decoration: none;
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px;
      width: 100%;
      border-top: 1px solid var(--default-gray-200, #e5e7eb);
      justify-content: center;
      color: var(--merge-primary-75, #8d66fe);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 0px;

      img {
        display: block;
      }
    }
  }
}

.profile-dropdown--visible {
  transition: all 0.4s ease;
  opacity: 1;
  right: 00px;
  visibility: visible;

  @media (min-width: 768px) {
    opacity: 1;
    visibility: visible;
    padding: 20px 0px;
    background-color: white;
    right: 20px;
  }
}

.user__sidebar__outside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(107, 114, 128, 0.58);
  z-index: 2;
  opacity: 0;
  transition: all 0.6s ease;
  visibility: hidden;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.user__sidebar__outside.visible {
  opacity: 1;
  transition: all 0.6s ease;
  visibility: visible;
  z-index: 2;
  height: 100%;
}