.heading {
    color: var(--default-gray-800, #1F2937);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;

    @media (max-width: 768px) {
        color: #1E293B;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}