.movie-details-container {
    margin-top: 2%;

    .movie-title {
        color: var(--default-white, #FFF);
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        max-width: 524px;
    }

    .movie-info-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 1%;
        margin-bottom: 2.5%;

        span {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }

    .movie-description {
        width: 524px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 20px;
    }
}


.movie-title {
    font-size: 40px;
    /* Minimum size */
}

/* Increase font size for larger screens */
@media (min-width: 600px) {
    .movie-title {
        font-size: calc(40px + (56 - 40) * ((100vw - 600px) / (1200 - 600)));
    }
}

/* Maximum font size */
@media (min-width: 1200px) {
    .movie-title {
        font-size: 56px;
    }
}