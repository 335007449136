.my-favorites-mobile-container {
    padding-bottom: 120px;
    height: 100%;

    .top-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background: var(--extended-gray-50, #FAFAFA);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

        .top-container-title {
            color: var(--default-gray-800, #1F2937);
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
        }
    }

    .menu {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 32px;
        margin-top: 12px;
        width: 100vw;
        overflow-x: auto;
        padding: 20px;

        .menu-item {
            padding: 8px 32px;
            color: var(--default-gray-800, #1F2937);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            border-radius: 12px;
            background: var(--extended-cool-gray-100, #F3F4F6);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
            border: none;
        }
    }

    .menu::-webkit-scrollbar {
        width: 0 !important;
        display: none !important;
    }




}

.favorites-shelf::-webkit-scrollbar {
    width: 0 !important;
    display: none !important;
}