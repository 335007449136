.input {
  &__label {
    color: #374151;
    font-size: 16px;
    margin-bottom: 8px;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  &__label.label_visible {
    display: block !important;
  }

  &__container {
    &__input {
      border: 1px solid #e5e7eb;
      border-radius: 4px;
      height: 48px;
      padding: 0 16px;
      font-size: 16px;
      color: #333;
      width: 100%;
      box-sizing: border-box;

      &:focus {
        outline: none;
        border-color: #aaa;
      }

      &::placeholder {
        color: #ccc;
      }
    }
  }
}
