.change__password__overlay {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.4 ease;
}

.change__password__overlay.visible {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
}

.change__password__modal {
  height: 637px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  border-radius: 16px;
  background: var(--default-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  opacity: 0;

  @media (min-width: 768px) {
    padding: 40px 0px;
    width: 533px;
    .top_container {
      background: var(--default-white, #fff);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px 32px;

      h2 {
        background: var(--default-white, #fff);
        color: var(--default-gray-800, #1f2937);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
      }

      img {
        cursor: pointer;
        background: var(--default-white, #fff);
      }
    }

    .form__content {
      padding: 0px 32px;
      background-color: #fff;
      display: flex;
      gap: 32px;
      flex-direction: column;

      .input__container,
      .input__container label,
      .input__container input,
      a,
      .buttons__container {
        background-color: #fff;
      }
    }
  }
}

.change__password__modal.visible {
  visibility: visible;
  z-index: 2;
  transition: all 0.4s ease;
  opacity: 1;
}

@media (max-width: 768px) {
  .change__password__overlay,
  .change__password__overlay {
    display: none;
  }

  .change__password__modal {
    top: 80px;
    left: 0;
    right: 0;
    transform: translate(0, 0);
    padding: 10px 20px;
    border-radius: 0;
    background: var(--default-white, #fff);
    box-shadow: none;
    height: 100%;
  }

  .top_container {
    background: var(--default-white, #fff);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;

    h2 {
      background-color: inherit;
      color: var(--default-gray-800, #1f2937);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    img {
      cursor: pointer;
      background-color: inherit;
    }
  }

  .form__content {
    padding: 0px 0px;
    background-color: #fff;
    display: flex;
    gap: 32px;
    flex-direction: column;

    .input__container,
    .input__container label,
    .input__container input,
    a {
      background-color: #fff;
    }

    .buttons__container {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      gap: 16px;

      button {
        margin: 0px;
      }
    }
  }
}
