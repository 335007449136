.table {
    width: 100%;
    max-width: 1110px;
    

    thead tr {
        border-bottom: 2px solid #d1d5db;
        display: flex;
        align-items: center;
        gap: 10px;
        padding-bottom: 10px;
    }  

    @media (max-width: 768px) {
        display: none;
    }
}

.header_titles {
    th {
        padding: 16px;
        color: var(--default-gray-500, #6B7280);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        
        text-decoration: none;
        background: transparent;
        border: 0px
    }
}

.episodeColumn {
    width: 30%;
    text-align: left;
    padding-left: 16px;
    border: 0px;
    background: transparent;
}

.episodeColumnBody {
    padding-left: 16px;
    width: 30%;
    border: 0px;
    // background-color: transparent;
    background: transparent;

    .song_name {
        color: var(--default-gray-800, #1F2937);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .song_artist {
        color: var(--default-gray-500, #6B7280);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: transparent;
        text-decoration: none;
        border: 0px;
    }
}
