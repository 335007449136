.search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 45px;
    margin-bottom: 30px;
    align-items: center;

    .search-input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 16px;
        border: 1px solid var(--default-gray-200, #E5E7EB);
        background: var(--default-white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        width: 100%;
        max-width: 524px;
        height: 56px;
        padding: 12px 16px;
        gap: 12px;
        cursor: pointer;

        input {
            outline: none;
            border: none;
            width: 100%;
        }
    }
}