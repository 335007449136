
.top-title {
    display: flex;
    align-items: center;
    gap:12px;
    cursor: pointer;
}
.mylist-moviepage-container {
    padding: 44px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
}

.movie-list-page-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}