.modal__overlay {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  opacity: 0;
  transition: all 0.4 ease;
}

.modal__container {
  height: 637px;
  width: fit-content;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  border-radius: 16px;
  background: var(--default-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  opacity: 0;
}

.modal__overlay.visible {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
  height: 100%;
}

.modal__container.visible {
  visibility: visible;
  z-index: 200;
  transition: all 0.4s ease;
  opacity: 1;
}

@media (max-width: 768px) {
  .modal__overlay.visible {
    visibility: visible;
    z-index: 200;
    transition: all 0.4s ease;
    opacity: 0;
  }

  .modal__container {
    top: 0px;
    height: 100%;
    width: 100%;
    transform: translate(0%, 0%);
    left: 0px;

  }

}