.modalContent {
  max-width: 720px;
  padding: 40px 32px;
  height: auto;
  max-height: 600px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.closeIcon {
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.photoBox {
  cursor: pointer;
  height: 220px;
  width: 220px;
  background-color: #E5E7EB;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.photoIcon {
  background: #FFF;
  height: 50px;
  width: 50px;
  padding: 12px;
  border-radius: 50%;
}

.addPhotoText {
  color: #6B37FE;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  min-width: 330px;
}

.inputText {
  height: 48px;
  padding: 13px 16px;
}

.textArea {
  height: 100%;
  padding: 13px 16px;
}

.createButton {
  height: 48px;
  margin-top: 24px;
  width: 228px;
  float: right;
  border-radius: 24px;
  background: #6B37FE;
  color: white;
  border: none;
  cursor: pointer;
}


@media (max-width: 840px) {
  .modalContent {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .photoBox {
      cursor: pointer;
      height: 133px;
      width: 133px;
      background-color: #E5E7EB;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    .createButton {
      width: 100%;
      float: auto;
      margin-top: auto;
    }


    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      min-width: 100%;
    }

    .inputText {
      height: 48px;
      padding: 13px 16px;
    }

    .textArea {
      height: 130px;
      padding: 13px 16px;
    }
  }
}