.merged_accounts__page__container {
  padding: 32px 44px;
  margin-left: 292px;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__column__left {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 20px;

      .hideMobile {
        display: block;
      }
      .hideDesktop {
        display: none;
      }
    }

    &__column__right {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .add__button {
        display: flex;
        width: 228px;
        height: 48px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 24px;
        background: var(--merge-primary-100, #6b37fe);
      }

      .choose_preferred__button {
        display: flex;
        height: 48px;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 24px;
        border: 1px solid var(--merge-primary-100, #6b37fe) !important;
        background-color: transparent;
        color: var(--merge-primary-100, #6b37fe);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
  @media (max-width: 768px) {
    padding: 16px 20px;
    margin-left: 0px;
    &__top {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      &__column__left {
        display: flex;
        align-items: center;
        flex-direction: row;

        .hideDesktop {
          display: block;
        }
        .hideMobile {
          display: none;
        }
      }

      &__column__right {
        display: flex;
        flex-direction: column;
        gap: 16px;
        // .add__button {
        //   display: none;
        // }
        .choose_preferred__button {
          width: 100% !important;
          padding: 16px 20px;
          
        }
      }
    }

    &__content {
      display: flex;
    }
  }
}
