.movies_filter_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    padding:10px 0px;
    position: relative;
    // overflow-x: auto;

    @media screen and (max-width: 840px) {
        gap: 8px;
        overflow-x: auto;
        // flex-direction: row-reverse;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .movieFilterItemButton {
        display: flex;
        height: 40px;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: var(--extended-cool-gray-100, #F3F4F6);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
        border: none;
        cursor: pointer;

        span {
            color: var(--default-gray-800, #1F2937);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;

            @media (max-width: 840px) {
                font-size: 12px;
            }
        }
    }
}



.provider-content {
    position: absolute;
    top: 45px;
    left: 0;
    background-color: #F3F4F6;
    width: 130%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    z-index: 999999999;
    border-radius: 12px;
    // overflow-x: auto;

    .provider-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 16px;
        border-radius: 12px;
        cursor: pointer;
        justify-content: space-between;

        &:hover {
            background-color: #E5E7EB;
        }

        span {
            color: var(--default-gray-800, #1F2937);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
        }

    }

}

.content-provider-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: max-content;
    display: flex;
    height: 40px;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--extended-cool-gray-100, #F3F4F6);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    border: none;
    cursor: pointer;
    position: relative;



    span {
        color: var(--default-gray-800, #1F2937);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }
}

