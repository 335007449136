.similiar-movies-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    overflow-x: auto;
    padding: 20px;

    &::-webkit-scrollbar {
        display: none;
    }

    .similar-movie-item {
        display: flex;
        width: 109px;
        height: 147px;
        padding: 12px 12px 111px 73px;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        background: var(--default-gray-200, #E5E7EB);
    }
}