.movies_headerbar_container {
    position: fixed !important;
    width: 100%;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 96px;
    padding: 0px 62px;
    background: rgba(250, 250, 250, 0.24);
    backdrop-filter: blur(8px);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(8px);
    z-index: 999;


    .movies_header_menu_container {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;

        .movies_header_menu {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .movies_header_title {
            margin-left: -18px;
            color: #58BB44;
            text-align: center;
            font-size: 27px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }



    }
}


@media (max-width: 768px) {
    .movies_headerbar_container {
        padding: 0px 20px;
    }
}



