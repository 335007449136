.profile-container {
  display: flex;
  align-items: center;
  float: right;
  border-radius: 64px;
  gap: 8px;
  position: relative;

  

  .arrow {
    margin-left: 10px;
    display: none;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.arrow-icon {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}
