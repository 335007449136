.settings-page-container {
    margin-left: 292px;
    padding: 44px;
    height: auto;
    padding-bottom: 100px;

    .react-switch-bg {
        border-radius: 12px;
        background: var(--merge-gradient-button, linear-gradient(180deg, #6B37FE 21.43%, #CA37FE 164.09%)) !important;
        svg {
          display: none;
        }
      }

    .settings-page-content-container {
        border-radius: 12px;
        padding: 16px 24px;
        margin-top: 32px;
        gap: 8px;
        display: flex;
        flex-direction: column;

        .settings-page-content-title {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-bottom: 25px;


        }

        .settings-item-container-top {
            display: flex;
            align-items: center;
            display: flex;
            padding: 16px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 12px;
            background: var(--default-gray-100, #F3F4F6);
            cursor: pointer;

            .settings-item-title {
                color: var(--default-gray-50, #F9FAFB);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }


        }

        .settings-item-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            width: 100%;
        }

        .options-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .settings-item-text {
        padding: 16px;
    }
}

@media (max-width:840px) {
    .settings-page-container {

        padding: 0px;
        margin-left: 0px;
        padding-bottom: 100px;

        .settings-page-title {
            margin-left: 16px;
            margin-top: 20px;
        }
    }
}