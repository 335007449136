.top-section-container {
    display: flex;
    flex-direction: row;
    gap: 28px;
    width: 100%;
    height: 247px;

    .playlist-photo-container {
        height: 100%;
        width: 247px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;
        border-radius: 8px;
        background: var(--extended-cool-gray-200, #E5E7EB);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
        flex-direction: column;
    }

    .top-right-section {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        
    }

    .artist_image_name {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .playlist-title {
        color: var(--default-gray-800, #1F2937);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
    }

    .playlist-length {
        color: var(--default-gray-500, #6B7280);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .buttons-container {
        margin-top: auto;
    }
}

@media (max-width:840px) {
    .top-section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        gap: 12px;

        .playlist-photo-container {
            min-height: 133px;
            width: 133px;
            background-size: contain;
        }

        .playlist-title {
            color: var(--default-gray-700, #374151);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            text-align: center;
        }

        .playlist-length {
            color: var(--default-gray-500, #6B7280);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            margin-bottom: 12px;
        }
    }
}