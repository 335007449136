/* Add this CSS to your stylesheet */

.player-container-mobile-spotify {
   
    background-color: #fff; /* Modal background */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    border-top-left-radius: 20px; /* Rounded corners */
    border-top-right-radius: 20px;
    transition: bottom 0.3s ease-in-out; /* Animation */
    overflow: hidden; /* In case content overflows */
    z-index: 1000; /* Ensure it's above other content */

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.player-container-mobile-spotify.active {
    background-color: #fff; /* Modal background */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    border-top-left-radius: 20px; /* Rounded corners */
    border-top-right-radius: 20px;
   
  

    position: fixed;
    bottom: -20px;
    left: 0;
    right: 0;
    /* z-index: 100; */
}

