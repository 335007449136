.profile__content__form {
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;

  .buttons__container {
    display: flex;
    flex-direction: column;
  }

  .save__button {
    background-color: transparent;
    color: #6b37fe;
    width: 228px;
    border-radius: 24px;
    border: 1px solid var(--merge-primary-100, #6b37fe);
    width: 100%;

    &:hover {
      background-color: var(--merge-primary-100, #6b37fe);
      color: white;
    }
  }

  @media (min-width: 768px) {
    gap: 32px;
    .buttons__container {
      flex-direction: row;
      justify-content: flex-end;
      gap: 8px;
    }

    .save__button {
      width: fit-content;
      min-width: 228px;
    }
  }
}
