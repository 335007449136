.movie-buttons-container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 30px;

    .button-watch {
        display: flex;
        width: 228px;
        height: 48px;
        padding: 12px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 36px;
        background: var(--merge-gradient-button, linear-gradient(180deg, #6B37FE 21.43%, #CA37FE 164.09%));
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
        border: none;
    }

    .button-watch-trailer {
        display: flex;
        width: 228px;
        height: 48px;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 36px;
        background: var(--extended-cool-gray-100, #F3F4F6);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    }

    .button-add-playlist {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 36px;
        background: var(--extended-cool-gray-100, #F3F4F6);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    }
}