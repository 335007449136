$--seek-before-color: #B37FEa;
$--progress-bar: blue;
@import url('');


.player_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 26px 12px 58px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 999;
    border-top: 1px solid var(--default-gray-200, #E5E7EB);
    background: var(--default-gray-50, #F9FAFB);
    height: 88px;
    justify-content: space-between;


    .player_info {
        display: flex;
        flex-direction: row;
        align-items: center;

        .player_info_name_artist {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 12px;
            margin-right: 24px;
            gap: 2px;

            .player_info_name {
                color: var(--default-gray-800, #1F2937);
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
            }

            .player_info_artist {
                color: var(--default-gray-500, #6B7280);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }
    }

    .player_options_buttons_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .player_options_buttons {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 24px;
        }

        .player_time_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 6px;
            gap: 8px;

            .current-time {
                width: 30px;
                color: var(--merge-primary-100, #6B37FE);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }

            .total-time {
                color: var(--default-gray-400, #9CA3AF);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }
    }

    .player_config_options {
        display: flex;
        align-items: center;

        .settings_icon {
            margin-right: 30px;
        }

        .volume_icon {
            margin-right: 10px;
        }

        .volume_input {
            height: 4px;
            background-color: #6B37FE;
            appearance: none;
        }

        .menu_icon {
            margin-left: 30px;
            margin-right: 10px;
        }
    }

    .favorite-button {
        margin-right: 16px;
    }
}

@media (max-width:768px) {
    .player_container {
        display: none;
    }

    .player-container-mobile {
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 60px;
        height: 56px;
        width: 100%;
        z-index: 9999999;

        background: linear-gradient(90deg, #1567AF 0%, #60A5E1 100%);
        padding: 0px 16px;
        gap: 12px;

        .player-song-artist-name {
            gap: 6px;
            display: flex;
            flex-direction: column;

            .player-music-name {
                color: var(--default-white, #FFF);
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
            }

            .player-music-artist {
                color: var(--default-gray-200, #E5E7EB);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }

        .player-favorite-play {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .player-img {
            height: 40px;
            width: 40px;


        }
    }
}