.search_header_movie_container {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0px 20px;
    width: 100%;
    height: 56px;
    max-width: 662px;
    margin-right: 40px;
    margin-left: 40px;
    border-radius: 16px;
    border: 1px solid var(--merge-primary-50, #B79FFF);
    background: var(--default-white, #FFF);

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    input {
        width: 100%;
        height: 100%;
        border: none;
    }

    .search__close {
        cursor: pointer;
    }
}