@import './assets/styles/colors.scss';

@font-face {
  font-family: "PlusJakartaSans Regular";
  src: url("./assets/fonts/PlusJakartaSans-Regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "PlusJakartaSans Medium";
  src: url("./assets/fonts/PlusJakartaSans-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  /* This is a typical weight for medium styles. Adjust if needed. */
}

@font-face {
  font-family: "PlusJakartaSans SemiBold";
  src: url("./assets/fonts/PlusJakartaSans-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  /* This is a typical weight for medium styles. Adjust if needed. */
}

.dark-mode {
  background: #293342;
}

body {
  font-family: "PlusJakartaSans Regular", sans-serif;
}


.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

:root {
  $--progress-bar-width: 0px;
  $--progress-bar-bg: red;
}