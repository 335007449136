.subscription__item__container {
  position: relative;
  padding: 24px 16px;
  border-radius: 8px;
  background: var(--extended-cool-gray-100, #f3f4f6);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &__top {
    background: var(--extended-cool-gray-100, #f3f4f6);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    h1 {
      background: var(--extended-cool-gray-100, #f3f4f6);
    }
  }

  &__content {
    background: var(--extended-cool-gray-100, #f3f4f6);
    display: flex;
    flex-direction: row;

    img {
      background-color: inherit;
    }

    .user_info__avatar {
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
    }

    .user_info {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      background-color: inherit;
      gap: 8px;

      span {
        background-color: inherit;
      }

      &__username {
        color: var(--default-gray-800, #1F2937);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }

      &__useremail {
        color: var(--default-gray-400, #9CA3AF);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }

      &__user__subscriptionprice {
        color: var(--default-gray-700, #374151);
        font-size: 12px;
        font-style: normal;
        font-weight: bolder !important;
        line-height: 130%;
      }
    }

    .right__content {
      margin-left: auto;
      align-items: center;
      display: flex;
      background-color: inherit;
      height: 26px;

      span,
      img {
        border-right: 1px solid var(--extended-cool-gray-100, #d1d5db);
        background-color: inherit;
        padding: 0px 12px;
        cursor: pointer;
      }
    }
  }

  hr {
    margin: 24px 0px;
    color: #d1d5db;
  }
}

@media (max-width: 768px) {
  .subscription__item__container {
    padding: 16px 20px;
    gap: 8px;
  }
}