.movie-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    position: relative !important;
    width: 268px;
    overflow: visible;
    z-index: 1;

    &:hover {
        z-index: 99;
        position: relative;

        .movie-img {
            z-index: 99;
            // height: 305px !important;
            position: relative;
        }

        @media (max-width: 840px) {
            .movie-img {
                width: 101px;
                height: 147px;
                position: relative;
            }
        }

        .movie-hover-detail {
            visibility: visible;
            opacity: 1;
            transform: translateY(20%);
            z-index: 9999;
            position: absolute;
            bottom: -0px;
            right: 0px;
        }
    }

    .movie-img {
        width: 100%;
        height: 182px;
        background-size: cover;
        background-position: center center;
        object-fit: fill;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        transform: scale(1);
        transform-origin: center center;
        z-index: 1;
    }

    .movie-title {
        margin-top: 12px;
        color: var(--default-gray-800, #1F2937);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .movie-hover-detail {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
        transform: translateY(50%);
        z-index: 10000000000000000000000;
        max-height: 175px;
        padding: 12px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        margin-bottom: -20px;


        .movie-detail-top-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .movie-detail-top-title {
                color: var(--default-gray-800, #1F2937);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            .movie-classification {
                border-radius: 6px;
                border: 1px solid var(--default-gray-200, #E5E7EB);
                padding: 2px 6px;
            }

        }

        .movie-detail-info-container {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-top: 12px;

            .movie-detail-info {
                border-radius: 36px;
                background: var(--extended-cool-gray-100, #F3F4F6);
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
                color: var(--default-gray-500, #6B7280);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                height: 40px;
                z-index: 12;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px 12px;
            }
        }
    }
}




@media (max-width: 840px) {
    .movie-container {
        width: 101px;
        height: auto;


        .movie-img {
            width: 100%;
            height: 147px;
            background-size: contain;
            // background-position: center center;
            object-fit: fill;
            border-radius: 10px;
            transition: all 0.3s ease-in-out;
            // transform: scale(1);
            transform-origin: center center;
            // background-color: #E5E7EB;
            z-index: 1;
        }

        .movie-title {

            display: none;
        }
    }
}