.all__genres__container {
  padding: 37px 30px;

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    gap: 8px;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 28px;

    .category_container {
      
      h4 {
        color: var(--default-gray-800, #1F2937);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin: 20px 0px;
      }

      &__items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        &__img {
          border-radius: 12px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 14px 16px;

    &__content {
      gap: 16px;

      .category_container {
       

        h4 {
          color: #1E293B;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 12px;
        }

        &__items {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 8px;

          &__img {
            height: 100px;
            width: 100px;
            border-radius: 12px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

  }
}