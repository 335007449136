.all__genres__container {
    margin-left: 292px;
    padding:37px 30px;

    &__heading {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        gap: 8px;
        cursor: pointer;
    }

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 28px;

        &__item {
            height: 126px;
            width: 248px;
        }
    }
    @media (max-width: 768px){
      padding:14px 16px;
      margin-left: 0px;
      &__content {
        gap:16px;

        &__item {
          height: 101px;
          width: 100px;
          border-radius: 12px;
          object-fit: cover;
          object-position: center;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

    }
}