.choose_experience_dropdown__container {
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--default-white, #fff);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  gap: 12px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  transition: all 0.3s ease;
  padding-bottom: 20px;

  .choose_experience__top {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--default-gray-200, #e5e7eb);
    width: 100%;
    padding: 12px 8px;
    align-items: center;

    h4 {
      color: var(--default-gray-800, #1f2937);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      position: absolute;
      left: 30%;
    }
  }

  .ul_dropdown {
    text-decoration: none;
    list-style: none;
    background: inherit;
    gap: 32px;
    display: flex;
    flex-direction: column;
    padding: 20px 0px;

    li {
      background-color: inherit;
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      position: relative;

      .title_stream_food {
        color: #fc9537;
      }

      .title_stream_socials {
        color: #9c78d7;
      }

      .title_stream_transport {
        color: #5fd5ef;
      }

      img {
        background-color: inherit;
      }

      h4 {
        color: #58bb44;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background-color: inherit;
      }

      .comming-soon-text {
        position: absolute;
        bottom: -20px;
      }
    }
  }

  @media (min-width: 768px) {
    top: 10px;
    left: 10px;
    transition: all 0.3s ease-in-out;
    width: 300px;
    display: block;
    padding: 27px;
    border-radius: 16px;

    .choose_experience__top,
    hr {
      display: none;
    }

    .ul_dropdown {

      li {
        h4 {
          font-size: 27px;
        }
      }
    }
  }
}

.choose_experience_dropdown__container.visible {
  transition: all 0.3s ease;
  visibility: visible;
  opacity: 1;
  position: fixed;
}

.choose_experience_dropdown__outside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 40;
  background: rgba(107, 114, 128, 0.58);
  visibility: hidden;
  opacity: 0;

  @media (min-width: 768px) {
    background: transparent
  }
}

.choose_experience_dropdown__outside.visible {
  transition: all 0.3s ease-in-out;
  visibility: visible;
  opacity: 1;
  position: fixed;
}