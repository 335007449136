.playlistpage_container {
    margin-left: 292px;
    padding:40px;

    .playlist-photo-container {
        
    }
} 

@media (max-width:840px) {
    .playlistpage_container {
        margin-left: 0px;
    }
}